import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const LookAfterEdges = () => (
  <Layout>
    <SEO
      title="Look After Your Edges"
      desciption="One of the most common complaints I see is about edges. They are definitely under-appreciated by many people because they think they will be there forever"
    />
    <h1>Look After Your Edges</h1>

    <p>One of the most common complaints I see is about edges. They are definitely under-appreciated by many people because everyone thinks their edges will be there forever. This leads to lack of proper care and edges are one of those things that you will miss when they are gone.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/g4bfYfU.jpg"
        alt="Look after your edges"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@leighannrenee"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Leighann Blackwood</a></p>
    </div>

    <h2>Your Edges Are The Weakest Part of Your Hair</h2>
    <p>They deserve separate treatment and extra care. Edges are most exposed to the elements so they lose moisture quickest. A good thing to do if you struggle with hydration between wash days is to add a small amount of conditioner around the edges of your hair to replace what you have lost.</p>
    <p>We talk about protective styles and while they are good for your hair in general, some of them are really bad for your edges. Take individual braids for example, smaller braids grip just the right amount of edge hair to add the extra strain that it just can’t take. You’ll find that the braid can fall out by itself or when you take them out, you find you have less hair.</p>
    <p>Even cornrows which can seem fine have two problems. The first comes at the installation process. Please, please, please don’t just sit there and let your hairdresser tug away at your edges with the aim of getting the tightest, neatest rows possible. If they feel too tight then they probably are. It’s not nice to be in a position where you can’t even frown without feeling like you are going to tear your scalp.</p>
    <p>Think less about how long you can retain your cornrows by making them as tight as possible to save money and think more about looking after your scalp and keeping your edges.</p>
    <p>The other problem is that your edges are usually the first part of your hair to unravel and again, if you leave them exposed to the elements they are likely to dry out, weaken and end up breaking off over time.</p>
    <p>Another culprit for taking edges is certain hair glue for weaves. If your edges are weak or you use the wrong hair glue then you could be putting unnecessary extra strain on your hair. You might not notice until it’s too late because you’ve been wearing the weave the whole time and when the time comes to remove it, there are no edges left.</p>
    <p>The problem with losing edge hair is that it often isn’t noticed until it’s too late. It can take months if not years of repeated abuse and by then it’s too late. The hair follicles are usually weekend beyond repair so the best thing you can do is prevent the damage in the first place.</p>

    <h3>So we’ve looked at the different ways you can lose your edges but how do we keep them?</h3>
    <p>In simple terms, you can start by doing the opposite of what I’ve written above. Understand that your edge hair isn’t necessarily more delicate than the rest of your hair but it is more susceptible to damage because it is the most exposed. Therefore it’s essential that you make an extra effort to add moisture when it’s lost. Don’t be afraid to add moisture every day if you need to.</p>
    <p>There are other things you can do like wear a sleeping cap at night but try to avoid elastic headbands because they can put extra strain on your edges. Go for the products where the band is covered in the same silk or satin material as the cap.</p>
    <p>Those methods are about prevention but for damage control, some people recommend onion juice recipes or rice water to stimulate the hair follicles. Re-growing edges can be a long and frustrating job that takes a lot of patience. Write a journal and take pictures so you can track your progress. A good idea is to join a group, if you aren’t already part of one and as others who have dealt with the same problem how they did it.</p>

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default LookAfterEdges
